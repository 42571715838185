<template>
  <div class="behold-content-wrapper font-AvenirNext">
    <section class="pt-8 lg:pt-16 container-contents">
      <div>
        <Logo class="flex justify-start" />
      </div>

      <figure class="mt-20 lg:mt-120 flex justify-center">
        <img
          class="hidden lg:block"
          style="height: 187px; width: 188px"
          src="../assets/images/oasis/logo-small.png"
          alt="Oasis logo"
        />
        <img
          class="block lg:hidden w-24 h-24"
          src="../assets/images/oasis/logo-small.png"
          alt="Oasis logo"
        />
      </figure>
    </section>

    <article
      class="flex flex-wrap items-center justify-between w-full mt-24 lg:mt-120 px-4 lg:pr-6 lg:pr-16"
    >
      <figure class="w-full lg:w-1/2 mx-auto">
        <img
          class=""
          src="../assets/images/oasis/oasis-1.png"
          alt="Screenshot of Oasis App"
        />
      </figure>

      <figure class="w-full lg:w-2/6 mx-auto mt-11 lg:mt-0">
        <img
          class=""
          src="../assets/images/oasis/oasis-2.png"
          alt="Screenshot of Oasis App"
        />
      </figure>
    </article>

    <article class="container-contents pb-20 lg:pb-120">
      <div class="w-full lg:w-1/2 mt-20 lg:mt-12">
        <p class="banner-section-text xl:pr-4">
          Oasis is an automated smart home service provider which offers smart;
          lights, thermostats and doorbells to its users. Oasis’ new remote
          temperature adjustment feature allows its users to remotely change the
          temperature in their homes using smart devices anytime and from
          anywhere.
        </p>
      </div>
    </article>

    <vm-back-top :bottom="100" :duration="1000" :timing="'ease'">
      <div class="back-to-top-arrow">
        <svg
          class="h-12 w-12 lg:w-79 lg:h-79"
          viewBox="0 0 79 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.4999 75.9402C59.6253 75.9402 75.9402 59.6253 75.9402 39.4999C75.9402 19.3744 59.6253 3.05957 39.4999 3.05957C19.3744 3.05957 3.05957 19.3744 3.05957 39.4999C3.05957 59.6253 19.3744 75.9402 39.4999 75.9402Z"
            fill="#8F4F4F"
            fill-opacity="0.24"
          />
          <path
            d="M56.9706 48.0055C55.7773 49.1927 53.8498 49.1927 52.6565 48.0055L39.5306 34.8796L26.4047 48.0055C25.1227 49.104 23.189 48.954 22.0906 47.6721C21.1085 46.5278 21.1085 44.8358 22.0906 43.6915L37.3888 28.3933C38.5821 27.2061 40.5096 27.2061 41.7029 28.3933L57.0012 43.6915C58.1792 44.894 58.1669 46.8214 56.9706 48.0055Z"
            fill="white"
          />
        </svg>
      </div>
    </vm-back-top>

    <article
      class="py-20 lg:py-120 text-red bg-red-light usability-testing oasis-context"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Context</h5>
          <p class="article-paragraph font-medium">
            One of the values we as a company (Enle Inc.) highlighted as being
            important to us is continuous learning. Based on this, we took part
            in a project-based mentorship program to help our technical team
            grow.
          </p>
          <p class="article-paragraph mt-2 font-medium">
            Each team member was assigned a mentor who is technically
            experienced and had agreed to devote a certain amount of time to the
            program. During this time, mentees and mentors were to work on an
            area of growth for the mentee which involved concrete outcomes.
          </p>
        </div>
      </div>
    </article>

    <section class="py-20 lg:py-120 how-we-got-here bg-white">
      <article class="container-contents">
        <div class="container mx-auto lg:w-2/3">
          <h5 class="article-title">Goals & Deliverables</h5>
          <p class="article-paragraph mt-6 lg:mt-8">
            To kick things off, we (my mentor & I) came up with the following
            goals:
          </p>

          <ul class="list-disc ml-4 mt-4 lg:mt-6">
            <li class="my-4 article-paragraph">
              Learn to design for one value proposition across multiple devices
              and use cases
            </li>
            <li class="my-4 article-paragraph">
              Documentation (product goals/vision, company goals, the problems
              that need to be solved, target users, user stories, user goals,
              IA, user-flows etc)
            </li>
            <li class="my-4 article-paragraph">
              Presentable assets which show a summary of project, goals and
              outcomes
            </li>
          </ul>

          <p class="text-lg text-black-dark font-normal mt-16 lg:mt-20">
            We also highlighted the deliverables:
          </p>
          <ul class="list-disc ml-4 mt-6">
            <li class="my-4 article-paragraph">
              Visual designs and prototypes for mobile, smartwatch and voice
            </li>
            <li class="my-4 article-paragraph">
              Documentation (product goals/vision, company goals, the problems
              that need to be solved, target users, user stories, user goals,
              IA, user-flows etc)
            </li>
            <li class="my-4 article-paragraph">
              Presentable assets which show a summary of project, goals and
              outcomes
            </li>
          </ul>
        </div>

        <div class="w-full lg:w-5/6 mx-auto mt-20 lg:mt-120">
          <p
            class="text-gray-lighter text-center leading-8 lg:leading-43 font-semibold text-lg lg:text-2xl"
          >
            To kick the project off, a fictitious company working on achieving a
            specific goal was created. This helped in providing a brief for the
            project and putting some constraints in place.
          </p>
        </div>

        <figure class="w-full mx-auto mt-20 lg:mt-120">
          <img
            class="w-204 h-204 lg:w-406 lg:h-406 mx-auto"
            src="../assets/images/oasis/logo-big.png"
            alt="Oasis logo"
          />

          <figcaption class="text-center">
            <h6 class="text-black-dark text-lg lg:text-2xl mt-6 lg:mt-9">
              An automated smart home service provider
            </h6>
          </figcaption>
        </figure>
      </article>
    </section>

    <article
      class="py-20 lg:py-120 text-red bg-red-light usability-testing oasis-context"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">What is Oasis?</h5>
          <p class="article-paragraph font-medium">
            Oasis is an automated smart home service provider which offers
            smart; lights, thermostats and doorbells to its users. Based on
            feedback from users, the company was looking to add a new feature to
            its smart thermostat which would allow users to remotely change the
            temperature in their homes using smart devices i.e smartphones,
            smartwatches and voice assistants.
          </p>
          <div class="mt-8 lg:mt-12 flex justify-start">
            <div class="w-full text-left relative">
              <a
                href="https://docs.google.com/document/d/1mTgl1u9G-MEbdk4r8pGwNYGurHvfi40OaAtTphJe1mo/edit?usp=sharing"
                target="_blank"
                class="oasis-link-border outline-none focus:outline-none article-link text-red"
              >
                Read More About Oasis
              </a>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-2/3 mx-auto mt-20 lg:mt-120">
          <h5 class="article-title">Process & Documentation</h5>
          <p class="article-paragraph font-medium">
            Before jumping into the visual design, we clarified the target
            users, their goals, how users would interact and navigate through
            Oasis’ smart thermostat app, etc. To do so, I created user stories,
            critical user journey maps, information architecture diagrams and
            user flow diagrams. The diagrams and documents created are
            accessible in the process document.
          </p>
          <div class="mt-8 lg:mt-12 flex justify-start">
            <div class="w-full text-left relative">
              <a
                href="https://docs.google.com/document/d/1mTgl1u9G-MEbdk4r8pGwNYGurHvfi40OaAtTphJe1mo/edit#bookmark=id.d6j3sgjw5r6s"
                target="_blank"
                class="oasis-link-border outline-none focus:outline-none article-link text-red"
              >
                View the Process Document
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>

    <article class="py-20 lg:py-120 how-we-got-here bg-white">
      <div class="container-contents">
        <div class="lg:flex justify-between">
          <div class="w-full lg:w-1/2">
            <h5 class="article-title">Wireframing & Prototyping</h5>
            <p class="article-paragraph mt-6 lg:mt-8">
              Wireframes were helpful in putting together a prototype which was
              used for testing without spending too much time on visual design.
              Feedback from the usability tests were incorporated into the
              visual design.
            </p>
          </div>

          <div class="lg:w-30"></div>

          <div class="w-full lg:w-1/2 mt-16 lg:mt-0">
            <figure class="w-full">
              <img
                class=""
                src="../assets/images/oasis/oasis-4.png"
                alt="Screenshot of Oasis app"
              />
            </figure>
          </div>
        </div>
      </div>
    </article>

    <article
      class="py-20 lg:py-120 text-red bg-red-light usability-testing oasis-context"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">
            Usability Testing, Feedback & Iterations
          </h5>
          <p class="article-paragraph font-medium">
            A script was created along with questions for the usability test. 6
            participants were recruited and their responses/feedback were
            documented and analysed. The most common/important feedback were
            used to refine the product in the visual design stage.
          </p>
          <div class="mt-8 lg:mt-12 flex justify-start">
            <div class="w-full text-left relative">
              <a
                href="https://docs.google.com/document/d/1mTgl1u9G-MEbdk4r8pGwNYGurHvfi40OaAtTphJe1mo/edit#bookmark=id.r8vmom3mjodi"
                target="_blank"
                class="oasis-link-border outline-none focus:outline-none article-link text-red"
              >
                View Summary of User Testing Results
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>

    <article
      class="py-20 lg:py-120 text-black-dark bg-white from-product-requirement"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Visual Design & Prototypes</h5>
          <p class="article-paragraph font-normal" style="font-weight: 400">
            The visual design and prototype were generated based on the
            wireframes and recommendations from the usability tests. I also
            recorded how interactions between users, a voice assistant and the
            smart thermostat would occur based on the voice assistant user flow
            diagram.
          </p>
        </div>

        <div class="flex flex-col lg:flex-row items-center mt-16 lg:mt-120">
          <figure class="lg:w-3/5">
            <img
              class=""
              src="../assets/images/oasis/oasis-5.png"
              alt="Screenshot of Oasis app"
            />
          </figure>

          <div class="text-left relative lg:ml-28 mt-2 lg:mt-0">
            <a
              href="https://www.figma.com/proto/gUnr0rtzJ8kBgL4EbLUd6b/Oasis-Thermostat?node-id=468%3A3417&viewport=758%2C-163%2C0.21154381334781647&scaling=scale-down&page-id=468%3A0"
              target="_blank"
              class="oasis-link-border outline-none focus:outline-none article-link text-red"
            >
              View the Mobile App Prototype
            </a>
          </div>
        </div>

        <div
          class="flex flex-col lg:flex-row items-center justify-between mt-16 lg:mt-120"
        >
          <div class="text-left relative mt-12 lg:mt-0 order-2 lg:order-1">
            <a
              href="https://www.figma.com/proto/gUnr0rtzJ8kBgL4EbLUd6b/Oasis-Thermostat?node-id=1%3A4&viewport=587%2C308%2C0.4854545593261719&scaling=scale-down&page-id=1%3A3"
              target="_blank"
              class="oasis-link-border outline-none focus:outline-none article-link text-red"
            >
              View the Smartwatch App Prototype
            </a>
          </div>
          <figure class="lg:w-3/5 order-1 lg:order-2 lg:pl-8">
            <img
              src="../assets/images/oasis/oasis-6.png"
              alt="Screenshot of Oasis app"
            />
          </figure>
        </div>

        <div class="flex flex-col lg:flex-row items-center mt-16 lg:mt-60">
          <figure class="lg:w-3/5">
            <img
              class=""
              src="../assets/images/oasis/oasis-7.png"
              alt="Screenshot of Oasis app"
            />
          </figure>

          <div class="text-left relative lg:ml-28 mt-2 lg:mt-0">
            <a
              href="https://drive.google.com/file/d/1EigEC0dAKT9JKtYmym-QVgUJDNSbOBvn/view"
              target="_blank"
              class="oasis-link-border outline-none focus:outline-none article-link text-red"
            >
              View the Voice Assistant Recording
            </a>
          </div>
        </div>
      </div>
    </article>

    <article
      class="py-20 lg:py-120 text-red bg-red-light usability-testing oasis-context"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Recap & Takeaways</h5>
          <p class="article-paragraph font-medium">
            Looking back, all the goals of this project were achieved - the most
            important one being “learning to design for one value proposition
            across multiple devices and use cases”. Grey areas in the project
            were clarified using familiar and unfamiliar processes. This
            provided an opportunity to learn new ways and practise familiar ways
            of sharing information across cross-functional teams. A more
            detailed version of the project takeaways can be viewed by clicking
            the button below.
          </p>
          <div class="mt-8 lg:mt-12 flex justify-start">
            <div class="text-left relative mt-2 lg:mt-0">
              <a
                href="https://docs.google.com/document/d/1mTgl1u9G-MEbdk4r8pGwNYGurHvfi40OaAtTphJe1mo/edit#bookmark=id.acvexidhq3te"
                target="_blank"
                class="oasis-link-border outline-none focus:outline-none article-link text-red"
              >
                View More Project Takeaways
              </a>
            </div>
          </div>

          <p
            class="mt-20 lg:mt-120 leading-8 font-semibold text-red text-lg lg:text-2xl"
          >
            For a more detailed documentation of the project, please check out
            the
          </p>
          <div class="mt-8 flex justify-start">
            <div class="relative mt-2 lg:mt-0">
              <a
                href="https://docs.google.com/presentation/d/1VRpYGK2xW_LjohW2gpjPKABIb5jd5gm2FyRIerdSSfc/edit?usp=sharing"
                target="_blank"
                class="oasis-link-border outline-none focus:outline-none article-link text-red"
              >
                Oasis Slide Deck
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>

    <article
      class="py-20 lg:py-120 text-black-dark bg-white usability-testing oasis-context"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Thanks</h5>
          <p
            class="mt-6 lg:mt-8 font-normal text-black-dark text-lg leading-8 lg:leading-10"
          >
            Thanks to my mentor David for guiding me through this project, I
            learned a lot! We well exceeded the 12 week time period but he was
            happy to work with me till the end. Thanks also to Ope and Enle Inc.
            for the opportunity. I had no idea how the program would go at the
            start but really happy with how it turned out.
          </p>
        </div>
      </div>
    </article>

    <div class="lg:h-20"></div>

    <div class="container-contents flex flex-col lg:flex-row justify-between">
      <div class="mb-20 lg:mb-0">
        <p class="text-gray-lighter text-center lg:text-left font-semibold">
          PREVIOUS
        </p>
        <div class="flex justify-center items-center mt-6 lg:mt-8">
          <div class="w-full text-center lg:text-left relative">
            <a
              href="/behold"
              class="oasis-link-border outline-none focus:outline-none article-link text-red text-lg"
            >
              Behold Case Study
            </a>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Logo from "@/components/Logo";
export default {
  name: "Oasis",
  components: { Logo, Footer },
};
</script>

<style lang="scss" scoped>
.oasis-link-border {
  position: relative;
  text-decoration: none;

  &::before {
    background: #8f4f4f;
    bottom: -4px;
    left: 0;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
  }
  &:hover {
    &::before {
      animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
  &:hover:before {
    animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}
@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
